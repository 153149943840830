import { TrackJS } from 'trackjs'
import { version } from './package.json'

const token = process.env.GATSBY_TRACKJS_TOKEN
const application = process.env.GATSBY_TRACKJS_APPLICATION

if (application) {
  TrackJS.install({
    token,
    application,
    version,
  })
}
